import React from "react"

const Video = ({ url }) => {
  return (
    <section className="video-container">
      <video
        src={url}
        controls
        controlsList="nodownload"
        playsInline
        loop
        autoPlay
        muted
        preload="auto"
        className="video"
      />
    </section>
  )
}

export default Video
